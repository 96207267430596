import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import ReactDOM from 'react-dom';
import { useTranslation } from 'react-i18next';
import axios from './Api';
import AuthContext from './AuthService';

/**
 * Modal for auto logout
 * @param {*} props
 * @returns {JSX} component
 */
const Modal = (props) => ReactDOM.createPortal(
  <div
    id="modalCover"
    onClick={props.onDismiss}
    onKeyDown={(e) => {
      if (e.key === 'Enter') {
        props.onDismiss();
      }
    }}
    role="button"
    tabIndex={-1}
    style={{ position: 'fixed', zIndex: '10' }}
  >
    <div
      onClick={(e) => e.stopPropagation()}
      onKeyDown={(e) => {
        if (e.key === 'Enter') {
          e.stopPropagation();
        }
      }}
      role="button"
      tabIndex={-1}
      id="modalContainer"
    >
      <div className="header">{props.title}</div>
      <div className="content">{props.content}</div>
      <div className="actions">{props.actions}</div>
    </div>
  </div>,
  document.querySelector('#modal'),
);

/**
 * Defines a component which will automatically log the user out aftert a given amount of time.
 * Will show a warning before logging them out.
 * @param {*} props The props being passed into the component
 * @returns {JSX} The components jsx
 */
export default function AutoLogout(props) {
  const { t } = useTranslation('common');
  const numMilliSecondsInMinute = 1000 * 60;
  const activityEvents = [
    'load', 'mousemove', 'mousedown', 'click', 'scroll', 'keypress',
  ];
  const { warningTime, logoutTime, saveState } = props;
  const warnTimeout = useRef();
  const logoutTimeout = useRef();
  const [showingWarn, setShowingWarn] = useState(false);
  const [timeIdle, setTimeIdle] = useState(0);

  const snapshot = useRef(saveState);

  /**
   * Sets the showingWarn flag to true, which will trigger the warning modal to show
   * @returns {void}
   */
  const warn = () => {
    setShowingWarn(true);
  };
  /**
   * Sets the showingWarn flag to false, which will trigger the warning modal to be removed
   * @returns {void}
   */
  const dismissWarn = () => {
    setShowingWarn(false);
  };
  /**
   * Logs out the user from the application
   * @returns {void}
   */
  const logout = async (snap) => {
    try {
      if (snap._id) await axios.post('/plan/temp', snap);
    } finally {
      AuthContext().logOut();
    }
  };
  /**
   * Clears the warning and logout timers
   * @returns {void}
   */
  const clearTimeouts = () => {
    clearTimeout(warnTimeout.current);
    clearTimeout(logoutTimeout.current);
  };
  /**
   * Sets the warning and logout timers
   * @returns {void}
   */
  const setTimeouts = () => {
    warnTimeout.current = setTimeout(warn, warningTime * numMilliSecondsInMinute);
    logoutTimeout.current = setTimeout(
      () => logout(snapshot.current),
      logoutTime * numMilliSecondsInMinute,
    );
  };
  /**
   * Increments the time idle counter
   * @returns {void}
   */
  const updateIdleTime = () => {
    setTimeIdle(timeIdle + 1);
  };
  /**
   * Resets the timers and (if necessary) removes the warning modal
   * @returns {void}
   */
  const resetTimeout = () => {
    setTimeIdle(0);
    clearTimeouts();
    setTimeouts();
    if (showingWarn) {
      dismissWarn();
    }
  };
  /**
   * Function equivalent to the componentWillMount method in a React class component.
   * Adds event listeners to activities that will reset the idle timeout.
   * @returns {function} clean up function
   */
  useEffect(() => {
    activityEvents.forEach((event) => {
      window.addEventListener(event, resetTimeout);
    });
    setTimeouts();
    /**
     * Returned function equivalent to the componentWillUnmount method in a React class component.
     * Removes the event listeners upon unmounting the component.
     * @returns {void}
     */
    return () => {
      clearTimeouts();
      activityEvents.forEach((event) => {
        window.removeEventListener(event, resetTimeout);
      });
    };
  }, []);
  /**
   * Resets the timeIdle value each time the idle warning modal is dismissed
   * @returns {void}
   */
  useEffect(() => {
    if (showingWarn) {
      setTimeIdle(0);
    } else resetTimeout();
  }, [showingWarn]);
  /**
   * Resets timeouts when plan changes
   */
  useEffect(() => {
    snapshot.current = saveState;
    if (snapshot.current._id) resetTimeout();
    return () => {
      clearTimeouts();
    };
  }, [saveState]);
  /**
   * Sets an interval to periodically update the idle timer
   * @returns {function} Clean up function
   */
  useEffect(() => {
    const idleTimer = setInterval(updateIdleTime, numMilliSecondsInMinute);
    /** Forces log out if minutes are below/equal to 0 */
    if (logoutTime - warningTime - timeIdle <= 0) {
      logout(snapshot.current);
    }
    return () => clearInterval(idleTimer);
  }, [timeIdle]);

  if (showingWarn) {
    return (
      <Modal
        title={t('inactivityWarning.title')}
        content={t('inactivityWarning.message', { minutes: logoutTime - warningTime - timeIdle })}
        actions={(
          <button
            type="button"
            className="button-blue"
            id="dismissWarn"
            onClick={dismissWarn}
          >
            {t('inactivityWarning.button')}

          </button>
        )}
        onDismiss={dismissWarn}
      />
    );
  }
  return null;
}

AutoLogout.defaultProps = {
  // Sets the default time before warning the user to 5 minutes
  warningTime: 5,
  // Sets the default time before logging out to 15 minutes
  logoutTime: 15,
  saveState: { _id: null },
};

AutoLogout.propTypes = {
  warningTime: PropTypes.number,
  logoutTime: PropTypes.number,
  saveState: PropTypes.shape({
    _id: PropTypes.string,
  }),
};
