import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { MultiSelect } from 'react-multi-select-component';
import '../styles/components/ReportsSelect.scss';

export const ReportsSelect = (props) => {
  const { options, setSelected: setPropSelected } = props;

  const { t } = useTranslation('common');
  const [stateOption, setOption] = useState([]);
  const [selected, setSelected] = useState([]);
  const [isOpen, setIsOpen] = useState(false);

  const onSelectedChange = (newSelected) => {
    setSelected(newSelected);
    setPropSelected(newSelected);
  };

  useEffect(() => {
    if (options) {
      // If options is passed in with value: x, label: y format for EVPs, VPs, and AVPs
      let newOptions;
      if (!(options[0] && options[0].label && options[0].value)) {
        newOptions = options.map((option) => ({ value: option, label: t(option) }));
      } else {
        // Set options label to t(label)
        options.forEach((obj, index) => {
          options[index].label = t(obj.label);
        });
        newOptions = options;
      }
      newOptions.sort((a, b) => {
        const optionA = a.label.toLowerCase();
        const optionB = b.label.toLowerCase();
        if (optionA < optionB) return -1;
        if (optionA > optionB) return 1;
        return 0;
      });

      setOption(newOptions);
      setSelected(newOptions);
    }
  }, [options]);

  const multiSelectLocalization = {
    allItemsAreSelected: t('reports.allOptions'),
    clearSearch: 'Clear Search',
    clearSelected: 'Clear Selected',
    noOptions: 'No options',
    search: 'Search',
    selectAll: t('reports.selectAll'),
    selectAllFiltered: 'Select All (Filtered)',
    selectSomeItems: t('reports.selectSomeItems'),
    create: 'Create',
  };

  return (
    <>
      {isOpen || (selected.length === stateOption.length)
        ? (
          <MultiSelect
            id="report-multiselect"
            overrideStrings={multiSelectLocalization}
            hasSelectAll
            options={stateOption}
            value={selected}
            onChange={onSelectedChange}
            isMulti
            disableSearch
            defaultIsOpen={isOpen}
            onMenuToggle={() => { setIsOpen(!isOpen); }}
          />
        )
        : (
          <Select
            className={isOpen ? '' : 'select-closed'}
            id="report-select"
            autoFocus={false}
            value={selected}
            isMulti
            onChange={onSelectedChange}
            options={stateOption}
            defaultMenuIsOpen={false}
            onMenuOpen={() => { setIsOpen(true); }}
          />
        )}
    </>
  );
};

ReportsSelect.propTypes = {
  options: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.string),
    PropTypes.arrayOf(PropTypes.shape({
      value: PropTypes.string,
      label: PropTypes.string,
    }))]),
  setSelected: PropTypes.func.isRequired,
};

ReportsSelect.defaultProps = {
  options: [],
};

export default ReportsSelect;
