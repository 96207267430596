import axios from 'axios';
import { toast } from 'react-toastify';
import authContext from './AuthService';
import ApiConfig from '../config/ApiConfig';
import AdalConfig from '../config/AdalConfig';
import history from '../history';

/**
 *  Creates an axios instance with the baseURL set to the backend,
 *  and potentially more configuration.
 */
const instance = axios.create(ApiConfig);

/** ID of internal errors not caught by other handlers */
const toastInternalErrorId = 'internalError';

/** Ensures current authorization token is included with every request */
instance.interceptors.request.use((config) => {
  let authConfig = config;
  authContext().acquireToken(
    AdalConfig.endpoints.api,
    // eslint-disable-next-line no-unused-vars
    (message, token) => {
      if (token) {
        authConfig = {
          ...config,
          headers: {
            ...config.headers,
            authorization: `Bearer ${token}`,
          },
        };
      }
    },
  );
  return authConfig;
});

/** 401 handler */
const errorHandler = async (error) => {
  if (error.message.includes('401')) {
    try {
      let retryConfig = error.config;
      authContext().acquireToken(AdalConfig.endpoints.api,
        // eslint-disable-next-line no-unused-vars
        (message, token) => {
          if (token) {
            retryConfig = {
              ...retryConfig,
              headers: {
                ...retryConfig.headers,
                authorization: `Bearer ${token}`,
              },
            };
          }
        });
      return await axios.request(retryConfig);
    } catch (err) {
      // If we fail twice just log user out
      if (err.message.includes('401')) {
        history.replace('/unauthorized');
      }

      // If it's a different type of error let the user know
      if (err.config.method === 'get' && err.message.includes('500')) {
        toast.error(`${err}`, {
          toastId: toastInternalErrorId,
        });
      }
    }
  }

  return Promise.reject(error);
};

instance.interceptors.response.use(
  (response) => response,
  (error) => errorHandler(error),
);

export default instance;
