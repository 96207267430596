import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import Modal from 'react-modal';
import '../styles/components/MessageModal.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationCircle, faCheckCircle } from '@fortawesome/free-solid-svg-icons';

/** SaveErrorModal component */
const SaveErrorModal = ({
  isOpen,
  handleClose,
  message,
  saved,

}) => {
  const customStyles = {
    overlay: {
      zIndex: '99',
    },
    content: {
      top: '15%',
      height: '200px',
      width: '443px',
      display: 'flex',
      margin: 'auto',
      alignItems: 'stretch',
      flexDirection: 'column',
      justifyContent: 'space-between',
      zIndex: '99',
    },
  };
  const { t } = useTranslation('common');

  const renderButtons = () => <button id="ok-button" type="button" onClick={handleClose}>{t('ok')}</button>;
  return (
    <Modal isOpen={isOpen} handleClose={handleClose} style={customStyles} ariaHideApp={false}>
      {saved ? (
        <FontAwesomeIcon icon={faCheckCircle} className="checkmark" />)
        : (<FontAwesomeIcon icon={faExclamationCircle} className="exclamation" />)}
      <p className="modal-text">{message}</p>
      <div className="modal-buttons">{renderButtons()}</div>
    </Modal>

  );
};

SaveErrorModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  message: PropTypes.string.isRequired,
  saved: PropTypes.bool.isRequired,
};

export default SaveErrorModal;
