import React from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-modal';
import { useTranslation } from 'react-i18next';

import '../styles/components/CreatePlanModal.scss';

/** ConfirmDeleteModal functional component */
const ConfirmDeleteModal = ({
  lengthEqualOne, index, deleteBA, activityName, isOpen, handleClose, dropdown,
}) => {
  const customStyles = {
    overlay: {
      zIndex: '99',
    },
    content: {
      margin: 'auto',
      height: '200px',
      width: '443px',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      zIndex: '99',
    },
  };

  const { t } = useTranslation('common');

  return (
    <Modal isOpen={isOpen} handleClose={handleClose} style={customStyles} ariaHideApp={false}>
      {!lengthEqualOne ? (
        <>
          <p className="confirm-text">{t('deleteCriticalActivity', { activityName })}</p>
          <div className="modal-buttons">
            <button
              type="button"
              id="deleteButton"
              onClick={() => {
                if (dropdown) deleteBA(handleClose);
                else if (index >= 0) deleteBA(index);
              }}
            >
              {t('delete')}
            </button>
            <button type="button" id="cancelButton" onClick={handleClose}>
              {t('cancel')}
            </button>
          </div>
        </>
      ) : (
        <>
          <p className="confirm-text">{t('errorDeletingCriticalActivity')}</p>
          <div>
            <button className="ok-button" type="button" id="okButton" onClick={handleClose}>
              {t('ok')}
            </button>
          </div>
        </>
      )}
    </Modal>
  );
};

ConfirmDeleteModal.defaultProps = {
  dropdown: false,
  index: -1,
};

ConfirmDeleteModal.propTypes = {
  lengthEqualOne: PropTypes.bool.isRequired,
  index: PropTypes.number,
  deleteBA: PropTypes.func.isRequired,
  activityName: PropTypes.string.isRequired,
  isOpen: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  dropdown: PropTypes.bool,
};

export default ConfirmDeleteModal;
