import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Support } from 'kdc-component-library';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import authContext from '../../services/AuthService';
import Sidebar from '../Sidebar';
import '../../styles/components/pages/SupportPage.scss';

/** SupportPage component class */
export const SupportPage = (props) => {
  const [supportSidebar, setSupportSidebar] = useState(false);
  const { renderSidebar, dispatch } = props; // redux state
  const { t } = useTranslation('common');
  const history = useHistory();

  const supportContent = [{
    title: t('FAQ'),
    content: [
      {
        question: t('questionOne'),
        answer: t('answerOne'),
      },
      {
        question: t('questionTwo'),
        answer: t('answerTwo'),
      },
      {
        question: t('questionThree'),
        answer: t('answerThree'),
      },
    ],
  }];

  const sidebarClose = () => dispatch({ type: 'SIDEBAR' });

  const items = [
    {
      id: t('selectMyPlan'),
      onClick: () => {
        history.push('/');
        sidebarClose();
      },
      label: t('selectMyPlan'),
      className: 'top-item',
    },
    {
      id: t('runAReport'),
      onClick: () => {
        history.push('/report');
        sidebarClose();
      },
      label: t('runAReport'),
      className: 'top-item',
    },
    {
      id: t('support'),
      onClick: () => {
        history.push('/support');
        sidebarClose();
      },
      label: t('support'),
      className: 'landing-item',
    },
    {
      id: 'Home',
      label: t('home'),
      right: true,
      url: '/',
      className: 'landing-item',
    },
    {
      id: 'Logout',
      label: t('logout'),
      right: true,
      onClick: () => authContext().logOut(),
      className: 'landing-item',
    },
  ];

  useEffect(() => {
    setSupportSidebar(true);
    return () => {
      setSupportSidebar(false);
    };
  }, []);

  return (
    <>
      {renderSidebar && supportSidebar && (
        <Sidebar
          closeSidebar={() => {
            sidebarClose();
          }}
          items={items}
        />
      )}
      <div className="support-page page-border-grey">
        <Support content={supportContent} />
      </div>
    </>
  );
};

SupportPage.defaultProps = {
  renderSidebar: false,
  dispatch: null,
};

SupportPage.propTypes = {
  renderSidebar: PropTypes.bool,
  dispatch: PropTypes.func,
};

const mapStateToProps = (state) => ({
  renderSidebar: state.sidebar.renderSidebar,
});

export default connect(mapStateToProps)(SupportPage);
