import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import authContext from './services/AuthService';
import AdalConfig from './config/AdalConfig';
import configureStore from './store/configureStore';
import LoginPage from './components/pages/LoginPage';
import AppRouter from './routers/AppRouter';
import initializeI18n from './i18n';
import packageJson from '../package.json';
import 'normalize.css/normalize.css';
import './styles/styles.scss';

initializeI18n();
authContext().handleWindowCallback();
global.appVersion = packageJson.version;

if (
  window === window.parent
  && window === window.top
  && !authContext().isCallback(window.location.hash)
) {
  if (
    !authContext().getCachedToken(AdalConfig.clientId)
    || !authContext().getCachedUser()
  ) {
    ReactDOM.render(<LoginPage />, document.getElementById('app'));
  } else {
    authContext().acquireToken(AdalConfig.endpoints.api, (message, token) => {
      if (token) {
        /** Prepare redux store */
        const store = configureStore(token);

        ReactDOM.render(
          <Provider store={store}>
            <AppRouter />
          </Provider>,
          document.getElementById('app'),
        );
      }
    });
  }
}
